<script>
export default {
  name: 'publikasi-detail',
  data() {
    return {
      post: null
    }
  },
  mounted() {
    this.getDetailPost()
  },
  methods: {
    getDetailPost() {
      this.axios.get(`post/slug/${this.$route.params.slug}`)
        .then( response => {
          this.post = response.data.result.post
          let isViewed = this.saveLastView('post', this.post.id)
          if (isViewed) {
            this.setViewed(this.post.id)
          }
        })
        .catch( () => {
          this.$router.push('/404')
        })
    },
    setViewed(id) {
      this.axios.put(`post/${id}/view`)
      .then( () => {
        //console.log(response)
      })
    },
  }
}
</script>

<template>
  <div v-if="post">
    <div class="head-for-bg mobile-for-head" :style="{ backgroundImage: `url(${$store.getters.backgroundImage})` }">
      <img :src="$store.getters.backgroundImage" class="hide-mobile" alt="" />
      <div class="for-title pl-res-10 pr-res-10 top-30">
        <span>{{ post.title }}</span>
      </div>
    </div>
    <div class="container">
      <div class="_sub-container">
        <div class="breadcump" v-if="post.category != 'other'">
          <ul>
            <template v-if="post.category == 'article'">
              <li class="">
                <a class="hide-mobile" href="/article">Article dan Opini</a>
                <a class="hide-desktop" href="/article">Article dan Opini <i class="fa fa-angle-right"></i> </a>
                <h4 class="hide-desktop">{{ post.title }}</h4>
              </li>
            </template>
            <template v-else-if="post.category == 'announcement'">
              <li class="">
                <a  class="hide-mobile" href="/announcement">Pengumuman</a>
                <a class="hide-desktop" href="/announcement">Pengumuman <i class="fa fa-angle-right"></i> </a>
                <h4 class=" hide-desktop">  {{ post.title }}</h4>
              </li>
            </template>
            <template v-else>
              <li class="">
                <a href="/calendar">Kalender</a>
                <a class="hide-desktop" href="/calendar">Kalender <i class="fa fa-angle-right"></i> </a>
                <h4 class="hide-desktop">  {{ post.title }}</h4>
              </li>
            </template>
            <li class="hide-mobile width-70 w-res-50">
              <h4 class="">{{ post.title }}</h4> 
            </li>
          </ul>
        </div>
        <div class="content-detail-layanan">
          <img v-if="post.banner" :src="post.banner" alt="">
          <span class="d-block font-14px font-weight-6 mb-5">{{ post.title }}</span>
          <span class="d-block font-12 color-grey mb-10"> 
            {{ post.timestamp | date_only }}  
            <span class="mr-3 ml-3 ml-res-1 mr-res-1"> &#9679; </span>  
            {{ post.timestamp | time_only }} 
            <span class="mr-3 ml-3 ml-res-1 mr-res-1"> &#9679; </span>   
            Penulis : {{ post.user }}	 
            <span class="mr-3 ml-3 ml-res-1 mr-res-1"> &#9679; </span>    
            Dilihat {{ post.viewers }} kali 
          </span>
          <p class="ln-2 mt-2" v-html="post.content"></p>
          <p class="ln-2 mt-2" v-if="post.start_date !== null && post.end_date !== null">
            Acara : {{ post.start_date | date_only }} - {{ post.end_date | date_only }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>